thead th {
	font-weight: inherit;
}

.popover .popover-arrow {
	display: none !important;
}
.react-datepicker-wrapper {
	width: auto !important;
}

@media (min-width: 576px) {
	.ratio-1x1 {
		--bs-aspect-ratio: 60% !important;
	}
}
 